import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/styles/makeStyles";
import ReactLink from "@micado-digital/react-link/ReactLink";

const useStyles = makeStyles(theme => ({
	link: {
		display: "inline-flex"
	},
	image: {
		display: "block",
		height: "auto",
		width: 160,
		[theme.breakpoints.up("sm")]: {
			height: "auto",
			width: 200
		}
	}
}));

const Logo = ({ file }) => {
	const css = useStyles();

	return (
		<ReactLink className={clsx("mco-header__logo-link", css.link)} to="/">
			<img
				alt=""
				aria-label="logo"
				className={clsx("mco-header__logo-image", css.image)}
				loading="lazy"
				height={46}
				src={file}
				width={200}
			/>
		</ReactLink>
	);
};

Logo.propTypes = {
	file: PropTypes.string.isRequired
};

export default Logo;
