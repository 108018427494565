export const theme = {
	typography: {
		fontFamily: "'Encode Sans', sans-serif",
		fontSize: 16,
		fontWeightLight: 300,
		fontWeightRegular: 400,
		fontWeightMedium: 700,
		fontWeightBold: 700,
		h1: {
			fontFamily: "QuadraatHeadOffc",
			fontSize: "clamp(1.5rem, 5vw, 64px)",
			fontWeight: 700,
			lineHeight: 1
		},
		h2: {
			fontFamily: "QuadraatHeadOffc",
			fontSize: "clamp(1.5rem, 5vw, 42px)",
			fontWeight: 700,
			lineHeight: 1
		},
		h3: {
			fontFamily: "QuadraatHeadOffc",
			fontSize: "clamp(1.5rem, 5vw, 42px)",
			fontWeight: 700,
			lineHeight: 1
		},
		h4: {
			fontFamily: "QuadraatHeadOffc",
			fontSize: "clamp(1.5rem, 5vw, 32px)",
			fontWeight: 600,
			lineHeight: 1.25
		},
		h5: {
			fontFamily: "QuadraatHeadOffc",
			fontSize: "clamp(1.5rem, 5vw, 24px)",
			fontWeight: 600,
			lineHeight: 1.167
		},
		h6: {
			fontFamily: "QuadraatHeadOffc",
			fontSize: 17,
			fontWeight: 700,
			letterSpacing: "0.01em",
			lineHeight: 1.25
		},
		body1: {
			fontFamily: "'Encode Sans', sans-serif",
			fontWeight: 300,
			fontSize: 16,
			lineHeight: 1.875,
			letterSpacing: 0
		},
		body2: {
			fontWeight: 400,
			fontSize: 14,
			lineHeight: 1.714,
			letterSpacing: 0
		},
		subtitle1: {
			fontWeight: 400,
			fontSize: 16,
			lineHeight: 1.25,
			letterSpacing: 0
		},
		subtitle2: {
			fontWeight: 400,
			fontSize: 12,
			lineHeight: 1.5,
			letterSpacing: 2,
			textTransform: "uppercase"
		},
		overline: {
			fontWeight: 400,
			fontSize: 12,
			lineHeight: 1.5,
			letterSpacing: 5,
			textTransform: "uppercase"
		},
		caption: {
			fontWeight: 400,
			fontSize: 12,
			lineHeight: 1.3333,
			letterSpacing: 0.4,
			textTransform: "uppercase"
		},
		button: {
			fontFamily: "QuadraatHeadOffc",
			fontSize: 14,
			fontWeight: 700,
			letterSpacing: 2,
			textTransform: "uppercase"
		}
	},
	palette: {
		primary: {
			light: "#F25A6B",
			main: "#E2001A",
			dark: "#A30013"
		},
		secondary: {
			light: "#999999",
			main: "#787878",
			dark: "#595959 "
		},
		error: {
			light: "#F08080",
			main: "#D50B0B",
			dark: "# 8B0101"
		},
		warning: {
			light: "#FFDE77",
			main: "#FFC105",
			dark: "#9F7804"
		},
		info: {
			light: "#D7C19D",
			main: "#C8AA79",
			dark: "#AE9061"
		},
		success: {
			light: "#B2D975",
			main: "#71B800",
			dark: "#477301"
		},
		grey: {
			50: "#F1F1F1",
			100: "#F2F2F2",
			200: "#CCCCCC",
			300: "#B2B2B2",
			400: "#999999",
			500: "#808080",
			600: "#666666",
			700: "#4D4D4D",
			800: "#333333",
			900: "#1A1A1A",
			A100: "rgba(0, 0, 0, 0,1)",
			A200: "rgba(0, 0, 0, 0,3)",
			A400: "rgba(0, 0, 0, 0,5)",
			A700: "rgba(0, 0, 0, 0,8)"
		},
		text: {
			primary: "#1A171B",
			secondary: "#808080",
			disabled: "rgba(51, 51, 51, 0,4)"
		},
		divider: "#D8D8D8",
		background: {
			backdrop: "rgba(0, 0, 0, 0.65)",
			body: "#CCCCCC",
			header: "linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%)",
			headerScroll: "#1A1A1A",
			submenuDesktop: "rgba(25, 25, 25, 0.8)",
			mobileContainer: "#333333",
			footer02: {
				footer: "#FFFFFF"
			},
			teaser: "#FFFFFF",
			default: "#FFFFFF"
		}
	},
	shape: {
		borderRadius: 0
	},
	overrides: {
		MuiButton: {
			root: {
				color: "#e2001a !important",
				boxShadow: "none !important",
				fontFamily: "'Encode Sans', sans-serif",
				fontSize: 16,
				letterSpacing: 0.4,
				textTransform: "none",
				//height: "auto",
				//padding: "15px 40px",
				height: 45,
				padding: "0 20px"
			},
			contained: {
				background: "#e2001a !important",
				color: "#ffffff !important"
			},
			sizeSmall: {
				fontSize: 11,
				letterSpacing: 1,
				lineHeight: 1.273,
				//padding: "13px 20px"
				//height: "auto",
				height: 40,
				padding: "0 20px"
			},
			sizeLarge: {
				fontSize: 14,
				/*
				height: 64,
				paddingLeft: 16
				paddingRight: 16,
				*/
				height: 50,
				paddingLeft: 20,
				paddingRight: 20
			}
		},
		MuiAlert: {
			root: {
				alignItems: "center"
			}
		}
	},
	customTypography: {
		hero: {
			hero1: {
				fontFamily: "QuadraatHeadOffc",
				fontSize: "clamp(2.5rem, 8vw, 80px)",
				fontWeight: 700,
				hyphens: "auto",
				lineHeight: 1.1
			},
			hero2: {
				fontFamily: "QuadraatHeadOffc",
				fontSize: "clamp(2.5rem, 8vw, 80px)",
				fontWeight: 700,
				hyphens: "auto",
				lineHeight: 1.1
			}
		},
		menu: {
			mobile: {
				menu1: {
					fontFamily: "QuadraatHeadOffc",
					fontWeight: 400,
					fontSize: 20,
					lineHeight: 1.5
				},
				menu2: {
					fontFamily: "QuadraatHeadOffc",
					fontWeight: 400,
					fontSize: 16,
					lineHeight: 1.875
				}
			},
			desktop: {
				menu1: {
					fontFamily: "QuadraatHeadOffc",
					fontSize: 20,
					fontWeight: 400,
					lineHeight: 1.5
				},
				menu2: {
					fontFamily: "QuadraatHeadOffc",
					fontSize: 16,
					fontWeight: 700,
					lineHeight: 1.875
				}
			}
		},
		textLogo: {
			title: {
				fontFamily: "QuadraatHeadOffc",
				fontWeight: 700,
				fontSize: 28,
				lineHeight: 1
			},
			addition: {
				fontWeight: 400,
				fontSize: 12,
				fontStyle: "normal",
				letterSpacing: 2,
				lineHeight: 1.5,
				textTransform: "uppercase"
			}
		},
		footer02: {
			label: {
				fontFamily: "QuadraatHeadOffc",
				fontSize: 24,
				fontWeight: 400,
				lineHeight: 1
			},
			footermenu: {
				mobile: {
					level1: {
						fontFamily: "QuadraatHeadOffc",
						fontWeight: 700,
						fontSize: 12,
						letterSpacing: 1,
						lineHeight: 2.5
					},
					level2: {
						fontWeight: 300,
						fontSize: 16,
						lineHeight: 1.875
					}
				}
			},
			metamenu: {
				mobile: {
					level1: {
						fontFamily: "QuadraatHeadOffc",
						fontWeight: 400,
						fontSize: 12,
						letterSpacing: 1,
						lineHeight: 1.3333333333333333
					}
				}
			}
		},
		form: {
			field: {
				fontWeight: 400,
				fontSize: 16,
				letterSpacing: 0.4,
				lineHeight: 1.25
			},
			label: {
				fontWeight: 700,
				fontSize: 14,
				letterSpacing: 0.4,
				lineHeight: 1.286
			}
		},
		datepicker: {
			weekdays: {
				fontWeight: 700,
				fontSize: 12,
				lineHeight: 1.5
			}
		}
	},
	props: {
		MuiButtonBase: {
			disableRipple: true
		}
	}
};
