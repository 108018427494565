import React, { useEffect, useContext } from "react";
import axios from "axios";
import PageContext from "../context/pageContext";
import ReactMetaData01 from "@micado-digital/react-metadata/ReactMetadata01";
import DetectLayout from "./detectLayout";

const DetectPage = () => {
	const { REACT_APP_DOM_DATA_ON_FIRST_LOAD } = process.env;
	const [pageData, setPageData] = useContext(PageContext);

	useEffect(() => {
		async function getPage() {
			const { pathname } = window.location;
			let data = null;

			//! avoid multiple pageload because of rerendering

			if (pageData?.currentFetchedURL === pathname) {
				return;
			}

			if (window.MCO_PAGE_FETCH_TIME?.getTime()) {
				if (new Date().getTime() - window.MCO_PAGE_FETCH_TIME?.getTime() < 200) {
					return;
				}
			} else {
				window.MCO_PAGE_FETCH_TIME = new Date();
			}

			// fetch local data (from dom)

			if (!window.MCO_PAGE_LOADED && REACT_APP_DOM_DATA_ON_FIRST_LOAD === "true") {
				data = fetchLocalData();
			}

			// if local data available -> set pageData

			if (data) {
				setPageData({
					currentFetchedURL: pathname,
					data
				});
				window.MCO_PAGE_LOADED = true;
				return;
			}

			// fetch server data

			data = await fetchData();

			setPageData({
				currentFetchedURL: pathname,
				data
			});
		}

		getPage();
	});

	if (!pageData?.data) {
		return <></>;
	}

	return (
		<>
			<ReactMetaData01
				canonical={pageData?.data.meta?.canonical}
				description={pageData?.data?.meta?.description}
				index={pageData?.data?.meta?.indexable}
				image={pageData?.data?.meta?.image}
				keywords={pageData?.data?.meta?.keywords}
				lat={pageData?.data?.meta?.lat}
				lang={pageData?.data.lang}
				links={pageData?.data?.meta?.links}
				lng={pageData?.data?.meta?.lng}
				title={pageData?.data?.meta?.title}
			/>
			<DetectLayout />
		</>
	);
};

const fetchLocalData = () => {
	try {
		const DOMData = document.querySelector(`template[is="mco-dom-data"]`);
		if (!DOMData) return null;
		const JSONData = JSON.parse(DOMData.content.textContent);
		DOMData.remove();
		return JSONData;
	} catch (error) {
		return null;
	}
};

const fetchData = async () => {
	const { REACT_APP_PATH } = process.env;
	const dllPath =
		"/Micado.Web.JSONEngine/Micado.Web.JSONEngine.IO.Api.PageApi/GetJSONData.api?absoluteUrl=";

	let fetchURL = null;

	if (process.env.NODE_ENV === "production") {
		fetchURL = REACT_APP_PATH + dllPath + window.location.href;
	} else {
		fetchURL = REACT_APP_PATH + dllPath + REACT_APP_PATH + window.location.pathname;
	}

	const result = await axios.post(fetchURL, null, {
		headers: { Authorization: `Bearer ${localStorage.getItem("logintoken")}` }
	});
	return result.data;
};

export default DetectPage;
