import React, { Suspense, lazy } from "react";
import cloneDeep from "lodash/cloneDeep";
import map from "lodash/map";
import Container from "../../../helper/container";

const DropDown = lazy(() =>
	import(/* webpackChunkName: "reactDropDown" */ "../../../components/dropdown")
);
const ReactHeadline01 = lazy(() =>
	import(
		/* webpackChunkName: "reactHeadline01" */ "@micado-digital/react-headline/ReactHeadline01"
	)
);
const ReactFile01 = lazy(() =>
	import(/* webpackChunkName: "reactFile01" */ "@micado-digital/react-file/ReactFile01")
);
const ReactFormWeb01 = lazy(() =>
	import(/* webpackChunkName: "reactFormWeb01" */ "@micado-digital/react-form/ReactFormWeb01")
);
const ReactGallery01 = lazy(() =>
	import(
		/* webpackChunkName: "reactGallery01" */ "@micado-digital/react-gallery/ReactGallery01"
	)
);
const ReactList01 = lazy(() =>
	import(/* webpackChunkName: "reactList01" */ "@micado-digital/react-list/ReactList01")
);
const ReactText01 = lazy(() =>
	import(/* webpackChunkName: "reactText01" */ "@micado-digital/react-text/ReactText01")
);
const ReactImageText01 = lazy(() =>
	import(
		/* webpackChunkName: "reactImageText01" */ "@micado-digital/react-imagetext/ReactImageText01"
	)
);
const ReactReferences02 = lazy(() =>
	import(
		/* webpackChunkName: "reactReferences01" */ "@micado-digital/react-references/ReactReferences02"
	)
);
const ReactSingleImage01 = React.lazy(() =>
	import(
		/* webpackChunkName: "singleImage01" */ "@micado-digital/react-singleimage/ReactSingleImage01"
	)
);
const ReactSingleTeaser01 = lazy(() =>
	import(
		/* webpackChunkName: "reactSingleTeaser01" */ "@micado-digital/react-singleteaser/ReactSingleTeaser01"
	)
);
const ReactTable01 = lazy(() =>
	import(/* webpackChunkName: "reactTable01" */ "@micado-digital/react-table/ReactTable01")
);
const ReactVideo01 = lazy(() =>
	import(/* webpackChunkName: "reactVideo01" */ "@micado-digital/react-video/ReactVideo01")
);

const Elements = ({ items, lang }) => {
	if (!items) return <></>;

	let isInDropdown = false;
	let isDropdownStartTag = false;
	let isDropdownEndTag = false;
	let currentDropdown = null;

	let newElements = cloneDeep(items);

	newElements = newElements.filter(item => {
		if (item.tag === "basic-dropdown") {
			if (item.option === 1) {
				currentDropdown = null;
				isDropdownStartTag = false;
				isDropdownEndTag = true;
				isInDropdown = false;
			} else {
				currentDropdown = item;
				isDropdownStartTag = true;
				isDropdownEndTag = false;
				isInDropdown = true;
			}
		} else {
			isDropdownStartTag = false;
			isDropdownEndTag = false;
		}

		if (isInDropdown && !isDropdownStartTag && currentDropdown) {
			if (currentDropdown._subItems) {
				currentDropdown._subItems.push(item);
			} else {
				currentDropdown._subItems = [item];
			}
		} else if (!isDropdownEndTag) {
			return item;
		}

		return null;
	});

	const List = newElements.map(item => {
		return <React.Fragment key={item.id}>{parseElements(item, lang)}</React.Fragment>;
	});

	return <Suspense fallback={<></>}>{List}</Suspense>;
};

const parseElements = (item, lang) => {
	const { REACT_APP_PATH } = process.env;

	switch (item?.tag) {
		case "basic-headline":
			return (
				<Container
					key={item?.id}
					maxWidth="md"
					name="headline"
					variant={(item?.variant || 0) + 1}
				>
					<ReactHeadline01 text={item?.text} variant={item.variant} />
				</Container>
			);

		case "basic-text":
			return (
				<Container key={item?.id} maxWidth="md" name="text">
					<ReactText01 text={item?.text} />
				</Container>
			);

		case "basic-list":
			return (
				<Container key={item?.id} maxWidth="lg" name="list">
					<ReactList01
						textcontent={item?.textcontent}
						title={item?.title}
						variant={item?.variant}
					/>
				</Container>
			);

		case "basic-links":
			return (
				<Container key={item?.id} maxWidth="md" name="links">
					<ReactReferences02
						buttonSize="medium"
						buttonVariant="contained"
						elements={item?.elements}
						title={item?.title}
					/>
				</Container>
			);

		case "basic-gallery":
			return (
				<Container key={item?.id} maxWidth="lg" name="gallery">
					<ReactGallery01 elements={item?.elements} />
				</Container>
			);

		case "basic-download":
			return (
				<Container key={item?.id} maxWidth="lg" name="download">
					<ReactFile01 media={item?.media} title={item?.title} />
				</Container>
			);

		case "basic-imagetext":
			return (
				<Container key={item?.id} maxWidth="lg" name="imagetext">
					<ReactImageText01
						addition2={item?.addition2}
						option={item?.option}
						media={item?.media?.[0]}
						mediaFormats={{
							xs: "basic-imagetext-mobile",
							sm: "basic-imagetext"
						}}
						mediaPath={REACT_APP_PATH}
						title={item?.title}
						text={item?.text}
					/>
				</Container>
			);

		case "basic-singleimage":
			return (
				<Container key={item?.id} maxWidth="lg" name="singleimage">
					<ReactSingleImage01
						addition={item?.addition}
						option2={item?.option2}
						media={item?.media?.[0]}
						mediaFormats={{
							xs: "basic-singleimage-mobile",
							sm: "basic-singleimage"
						}}
						mediaPath={REACT_APP_PATH}
						reference={item?.reference}
						title={item?.title}
						text={item?.text}
					/>
				</Container>
			);

		case "basic-form":
			let message = "";

			if (lang === "de") {
				message =
					"Ich akzeptiere die <a href='https://www.kitzbuehel.com/datenschutz/' rel='noreferrer' target='_blank'>Datenschutzbestimmungen</a>";
			} else {
				message =
					"I accept the <a href='https://www.kitzbuehel.com/en/privacy-policy/' rel='noreferrer' target='_blank'>Privacy policy</a>";
			}

			return (
				<Container key={item?.id} maxWidth="md" name="form">
					<ReactFormWeb01
						id={item?.id}
						elements={item?.elements}
						spamCheck={1}
						privacyMessage={message}
					/>
				</Container>
			);

		case "basic-singleteaser":
			return (
				<Container key={item?.id} maxWidth="lg" name="singleteaser">
					<ReactSingleTeaser01
						buttonLabel={"Mehr erfahren"}
						teaser={item?.teaser}
						media={item?.teaser?.media?.[0]}
						mediaPath={REACT_APP_PATH}
						mediaFormats={{
							xs: "basic-singleteaser"
						}}
						variant={item?.variant}
					/>
				</Container>
			);

		case "basic-external-singleteaser":
			const title = item?.textcontent?.items.find(e => e.name === "Title")?.text;
			const text = item?.textcontent?.items.find(e => e.name === "Text")?.text;
			const link = item?.textcontent?.items.find(e => e.name === "URL")?.text;

			return (
				<Container key={item?.id} maxWidth="lg" name="external-singleteaser">
					<ReactSingleTeaser01
						buttonLabel={"Mehr erfahren"}
						teaser={{
							title: title,
							text: text,
							link: link,
							linkTarget: "_blank"
						}}
						media={item?.elements[0]?.media?.[0]}
						mediaPath={REACT_APP_PATH}
						mediaFormats={{
							xs: "basic-singleteaser"
						}}
						variant={item?.variant}
					/>
				</Container>
			);

		case "basic-video":
			return (
				<Container key={item?.id} maxWidth="lg" name="video">
					<ReactVideo01 title={item?.title} text={item?.text} media={item?.media} />
				</Container>
			);

		case "basic-table":
			return (
				<Container key={item?.id} maxWidth="lg" name="table">
					<ReactTable01
						addition={item?.addition}
						title={item?.title}
						items={item?.textcontent?.items}
					/>
				</Container>
			);

		case "basic-dropdown":
			return (
				<Container key={item?.id} maxWidth="lg" name="dropdown">
					<DropDown data={item}>{map(item?._subItems, parseElements)}</DropDown>
				</Container>
			);

		default:
			if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
				console.log("missing element:", item);
			}
			return null;
	}
};

export default Elements;
