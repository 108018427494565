import React, { useEffect, useState, Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import CssBaseline from "@material-ui/core/CssBaseline";
import createTheme from "@material-ui/core/styles/createTheme";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import { alpha } from "@material-ui/core/styles/";
import { theme as customTheme } from "./themes/kitzbuehel";
import DetectPage from "./helper/detectPage";
import ChangePage from "./helper/changePage";
import CSSProperties from "./utils/CSSProperties";
import MenuContext from "./context/menuContext";
import PageContext from "./context/pageContext";
import BookmarkRoot from "@micado-digital/react-bookmark/ReactBookmark/bookmarkRoot";
import WebProfile from "@micado-digital/react-web-profile/ReactWebProfile/webProfile";
import InternalRoot from "@micado-digital/react-internal/ReactInternal/internalRoot";
import {
	applyPolyfills as consentApplyPolyfills,
	defineCustomElements as consentDefineCustomElements
} from "@micado-digital/micado-consent/loader";
import {
	applyPolyfills as ialApplyPolyfills,
	defineCustomElements as ialDefineCustomElements
} from "@micado-digital/micado-ial/loader";
import "./css/index.css";

const theme = createTheme(customTheme);

const App = () => {
	const [pageData, setPageData] = useState({});
	const [menuOpen, setMenuOpen] = useState(false);

	useEffect(() => {
		CSSProperties(theme, alpha);
	}, []);

	return (
		<QueryClientProvider client={new QueryClient()}>
			<ThemeProvider theme={theme}>
				<Suspense fallback={<></>}>
					<WebProfile>
						<PageContext.Provider value={[pageData, setPageData]}>
							<InternalRoot>
								<BookmarkRoot>
									<MenuContext.Provider value={[menuOpen, setMenuOpen]}>
										<CssBaseline />
										<Router>
											<ChangePage />
											<Switch>
												<Route path="/*" component={DetectPage} />
											</Switch>
										</Router>
									</MenuContext.Provider>
								</BookmarkRoot>
							</InternalRoot>
						</PageContext.Provider>
					</WebProfile>
				</Suspense>
			</ThemeProvider>
		</QueryClientProvider>
	);
};

ReactDOM.render(<App />, document.getElementById("root"));

consentApplyPolyfills().then(() => {
	consentDefineCustomElements(window);
});
ialApplyPolyfills().then(() => {
	ialDefineCustomElements(window);
});
