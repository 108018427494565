import React, { useContext } from "react";
import clsx from "clsx";
import makeStyles from "@material-ui/styles/makeStyles";
import PageContext from "../../../context/pageContext";
import Elements from "./elements";
import WebProfileContext from "@micado-digital/react-web-profile/context/webProfileContext";

const ReactPwaAlert = React.lazy(() =>
	import(
		/* webpackChunkName: "reactPwaAlert" */ "@micado-digital/react-pwa-alert/ReactPwaAlert"
	)
);

const useStyles = makeStyles(theme => ({
	content: {
		padding: theme?.spacing(5, 0),
		[theme.breakpoints.up("sm")]: {
			padding: theme?.spacing(8, 0)
		}
	}
}));

const Standard = () => {
	const { REACT_APP_PATH } = process.env;
	const [pageData] = useContext(PageContext);
	const [profileData] = useContext(WebProfileContext);
	const css = useStyles();
	const items = pageData?.data?.elements;

	return (
		<>
			<div className={clsx(css.content, "mco-content")}>
				<Elements items={items} lang={pageData?.data?.lang}></Elements>
				{profileData?.pwaShowInstallHint && (
					<ReactPwaAlert appIcon={REACT_APP_PATH + "/img/pwa/apple-icon-180.png"} />
				)}
			</div>
			<micado-consent path={REACT_APP_PATH}></micado-consent>
			<micado-ial path={REACT_APP_PATH}></micado-ial>
		</>
	);
};

export default Standard;
