import { useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";
import MenuContext from "../context/menuContext";

const ChangePage = ({ history }) => {
	const setMenuOpen = useContext(MenuContext)[1];

	useEffect(() => {
		const unlisten = history.listen(() => {
			setMenuOpen(false);
			window.scrollTo({ top: 0, behavior: "smooth" });
		});
		return () => {
			unlisten();
		};
	}, [history, setMenuOpen]);

	return null;
};

export default withRouter(ChangePage);
