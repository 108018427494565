import React, { useContext, Suspense } from "react";
import clsx from "clsx";
import makeStyles from "@material-ui/styles/makeStyles";
import PageContext from "../context/pageContext";
import LayoutStandard from "../views/template/standard";
import Error404 from "../views/template/errors/404";
import Header from "../views/components/header";

const useStyles = makeStyles(theme => ({
	page: {
		display: "flex",
		flexDirection: "column",
		minHeight: "100vh",
		paddingTop: "calc(env(safe-area-inset-top) + 60px)",
		overflow: "hidden",
		[theme.breakpoints.up("sm")]: {
			paddingTop: "calc(env(safe-area-inset-top) + 100px)"
		}
	}
}));

const DetectLayout = () => {
	const [pageData] = useContext(PageContext);
	const css = useStyles();

	if (!pageData || !pageData.data) {
		return <></>;
	}

	const { layoutTag, layoutVariant, state } = pageData?.data;

	switch (layoutTag) {
		case "standard":
			return (
				<div className={clsx(css.page, "mco-page")}>
					<Header />
					<Suspense fallback={<></>}>
						<LayoutStandard variant={layoutVariant} />
					</Suspense>
				</div>
			);
		default: {
			// errors

			// eslint-disable-next-line default-case
			switch (state) {
				case 404:
					return (
						<div className={clsx(css.page, "mco-page")}>
							<Header />
							<Suspense fallback={<></>}>
								<Error404 />
							</Suspense>
						</div>
					);
			}
		}
	}
};

export default DetectLayout;
