import React from "react";
import clsx from "clsx";
import makeStyles from "@material-ui/styles/makeStyles";
import Logo from "./logo";

const useStyles = makeStyles(theme => ({
	root: {
		alignItems: "center",
		background: "#FFFFFF",
		display: "flex",
		justifyContent: "center",
		height: 60,
		left: 0,
		padding:
			"env(safe-area-inset-top) " + theme?.spacing(2) + "px 0 " + theme?.spacing(2) + "px",
		position: "fixed",
		right: 0,
		top: 0,
		zIndex: theme?.zIndex?.appBar,
		[theme.breakpoints.up("sm")]: {
			height: 100,
			paddingLeft: theme?.spacing(3),
			paddingRight: theme?.spacing(3)
		}
	}
}));

const Header = () => {
	const css = useStyles();

	return (
		<header className={clsx("mco-header", css.root)}>
			<Logo file="/img/kitzbuehel.svg" />
		</header>
	);
};

export default Header;
